<template>
  <default-layout>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block__lg">
            <div class="c-block">
              <h1 class="c-heading__lv1"><span class="c-heading__title">アカウント情報</span></h1>
            </div>
            <alert></alert>
            <div class="c-block">
              <dl v-if="edit" class="c-form-block is-narrow">
                <dt><label class="c-form-label" for="">会員氏名（ローマ字）</label></dt>
                <dd>
                  <div class="c-input-en-name">
                    <div>
                      <label>名</label>
                      <input class="c-input__text" type="text" placeholder="" v-model="form.givenNameEn">
                      <field-error :errors="errors" name="givenNameEn"></field-error>
                    </div>
                    <div>
                      <label>姓</label>
                      <input class="c-input__text" type="text" placeholder="" v-model="form.familyNameEn">
                      <field-error :errors="errors" name="familyNameEn"></field-error>
                    </div>
                  </div>
                </dd>
                <dt><label class="c-form-label" for="">都道府県</label></dt>
                <dd>
                  <div class="c-input-select">
                    <select v-if="prefectures" v-model="form.prefecture">
                      <option value="" hidden>都道府県選択</option>
                      <option v-for="p in prefectures" :value="p.id" :key="p.id">{{p.name}}</option>
                    </select>
                    <field-error :errors="errors" name="prefecture"></field-error>
                  </div>
                </dd>
              </dl>
              <dl v-else-if="member.memberId" class="c-form-block is-narrow">
                <dt><label class="c-form-label" for="">会員氏名（ローマ字）</label></dt>
                <dd><span class="c-form-text">{{member.givenNameEn}} {{member.familyNameEn}}</span></dd>
                <dt><label class="c-form-label" for="">都道府県</label></dt>
                <dd><span class="c-form-text">{{member.prefecture ? member.prefecture.name : ''}}</span></dd>
              </dl>
            </div>
            <button v-if="edit" class="c-btn is-short u-ma u-mt20" type="button" @click="update()"><span>登録</span></button>
            <button v-else class="c-btn is-short u-ma u-mt20" type="button" @click="toEdit()"><span>編集</span></button>
            <button v-if="edit" class="c-btn__secondary is-short u-ma u-mt20" type="button" @click="edit = false"><span>キャンセル</span></button>
          </div>
          <div class="c-block__lg">
            <div class="c-block">
              <h2 class="c-heading__lv2"><span class="c-heading__title">登録情報</span></h2>
              <p class="c-text u-tac">以下の内容に間違いがある場合は所属の教室までご連絡ください。</p>
            </div>
            <div class="c-block">
              <dl class="c-form-block is-narrow" v-if="member.memberId">
                <dt><span class="c-form-label">教室名</span></dt>
                <dd><span class="c-form-text">{{member.siteName}}</span></dd>
                <dt><span class="c-form-label">英語検定ターゲットコースの会員番号</span></dt>
                <dd><span class="c-form-text">{{member.memberCode}}</span></dd>
                <dt><span class="c-form-label">会員氏名（漢字）</span></dt>
                <dd><span class="c-form-text">{{member.familyName}} {{member.givenName}}</span></dd>
                <dt><span class="c-form-label">会員氏名（カナ）</span></dt>
                <dd><span class="c-form-text">{{member.familyNameKana}} {{member.givenNameKana}}</span></dd>
                <dt><span class="c-form-label">生年月日</span></dt>
                <dd><span class="c-form-text">{{member.dateOfBirth | date('YYYY/MM/DD')}}</span></dd>
                <dt><span class="c-form-label">学年</span></dt>
                <dd><span class="c-form-text">{{member.schoolGrade.name}}</span></dd>
                <dt><span class="c-form-label">性別</span></dt>
                <dd><span class="c-form-text">{{member.sex.name}}</span></dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn__secondary is-short u-ma" type="button" @click="$move('/home')"><span>戻る</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data() {
    return {
      form: {
        memberId: "",
        familyNameEn: "",
        givenNameEn: "",
        prefecture: ""
      },
      edit: false,
      member: {},
      prefetctures: [],
      errors: {}
    };
  },
  created() {
    Service.prefectures().then(prefectures => this.prefectures = prefectures);
    Service.getMember().then(member => this.member = member);
  },
  methods: {
    toEdit() {
      this.form.memberId = this.member.memberId;
      this.form.familyNameEn = this.member.familyNameEn;
      this.form.givenNameEn = this.member.givenNameEn;
      this.form.prefecture = this.member.prefecture?.id;
      this.edit = true;
    },
    update() {
      Service.save(this.form)
          .then(result => {
            this.member.familyNameEn = result.familyNameEn;
            this.member.givenNameEn = result.givenNameEn;
            this.member.prefecture = result.prefecture;
            this.edit = false;
            this.$store.commit("clearNotification");
            this.errors = {};
          })
          .catch(e => {
            this.errors = e.fieldErrors;
          })
    }
  }
}
</script>
