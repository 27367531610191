<template>
  <default-layout>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">パスワード変更</span></h1>
          </div>
          <alert></alert>
          <div class="c-block">
            <dl class="c-form-block is-narrow">
              <dt>
                <label class="c-form-label" for="">現在のパスワード</label>
              </dt>
              <dd>
                <input class="c-input__text" type="password" placeholder="" v-model="form.currentPassword">
                <field-error :errors="errors" name="currentPassword"></field-error>
              </dd>
              <dt>
                <label class="c-form-label" for="">新しいパスワード</label>
              </dt>
              <dd>
                <input class="c-input__text" type="password" placeholder="" v-model="form.newPassword">
                <field-error :errors="errors" name="newPassword"></field-error>
                <div class="c-form-ex">
                  <p class="c-form-ex__text">英字・数字をそれぞれ１文字以上含め、8文字以上入力してください。</p>
                </div>
              </dd>
              <dt>
                <label class="c-form-label" for="">新しいパスワード<br class="u-pc">（確認用）</label>
              </dt>
              <dd>
                <input class="c-input__text" type="password" placeholder="" v-model="form.newPasswordConfirm">
                <field-error :errors="errors" name="newPasswordConfirm"></field-error>
                <div class="c-form-ex">
                  <p class="c-form-ex__text">上のパスワードと同じ内容を入力してください。</p>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div class="contents-block__footer">
          <div class="c-form-btn-group">
            <div class="c-form-btn-group__item">
              <button class="c-btn__secondary u-ma" type="button" @click="$move('/home')"><span>戻る</span></button>
            </div>
            <div class="c-form-btn-group__item">
              <button class="c-btn u-ma" type="button" @click="save" :disabled="!filled"><span>登録</span></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import {FieldError} from "@/lib/components";

export default {
  components: {
    FieldError
  },
  data() {
    return {
      form: {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      errors: {},
    }
  },
  computed: {
    filled() {
      return this.form.currentPassword.length > 0
          && this.form.newPassword.length > 0
          && this.form.newPasswordConfirm.length > 0;
    }
  },
  methods: {
    save() {
      this.$processing(() =>
        Service.save(this.form)
            .then(() => {
              this.$move("/home");
              this.$store.dispatch("notify", {
                type: "info",
                message: "パスワードを変更しました。",
              });
            })
            .catch(e => {
              this.errors = e.fieldErrors;
            })
      );
    },
  }
};

</script>
