<template>
  <default-layout>
    <form @submit="send">
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">メールアドレス変更</span></h1>
          </div>
          <alert></alert>
          <div class="c-block u-tac">
            <p class="c-text">新しいメールアドレスを入力してください。<br>入力されたメールアドレス宛に確認コードを送信します。</p>
          </div>
          <div class="c-block">
            <dl class="c-form-block is-narrow">
              <dt>
                <label class="c-form-label" for="">新しいメールアドレス</label>
              </dt>
              <dd>
                <input class="c-input__text" type="email" v-model="email">
                <field-error :errors="errors" name="email"></field-error>
              </dd>
            </dl>
          </div>
        </div>
        <div class="contents-block__footer">
          <div class="c-form-btn-group">
            <div class="c-form-btn-group__item">
              <button class="c-btn__secondary u-ma" type="button" @click="$move('/home')"><span>戻る</span></button>
            </div>
            <div class="c-form-btn-group__item">
              <button class="c-btn u-ma" type="button" @click="send" :disabled="email.length == 0"><span>送信</span></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data() {
    return {
      email: "",
      errors: {}
    }
  },
  methods: {
    send(e) {
      e.preventDefault();
      Service.sendConfirmationCode(this.email)
          .then(() => {
            this.$move("confirm");
          }).catch(e => {
            this.errors = e.fieldErrors;
          })
    }
  }
}
</script>
