<template>
  <default-layout>
    <form @submit="confirmCode">
      <input type="hidden" name="regist" value="true">
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">メールアドレス変更</span></h1>
          </div>
          <alert></alert>
          <div class="c-block">
            <div class="c-block u-tac">
              <p class="c-text">メールに記載されている確認コード（6桁の数字）を入力してください。</p>
            </div>
            <div class="c-block">
              <div class="c-input-code" :class="{ wrong: codeError }">
                <input class="c-input__text" type="text" placeholder="000000" maxlength="6" v-model="code">
              </div>
            </div>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn is-short u-ma" type="button" @click="confirmCode" :disabled="!filled"><span>送信</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import Alert from "@/lib/layout/Alert";

export default {
  components: {Alert},
  data() {
    return {
      code: '',
      codeError: false
    }
  },
  computed: {
    filled() {
      return this.code.length == 6;
    }
  },
  methods: {
    shake () {
      this.code = '';
      this.codeError = true;
      setTimeout(() => { this.codeError = false; }, 360);
    },
    confirmCode(e) {
      e.preventDefault();
      Service.confirmCode(this.code)
          .then(() => {
            this.$move("/home");
            this.$store.dispatch("notify", {
              type: "info",
              message: "メールアドレスを変更しました。",
            });
          })
          .catch(e => {
            this.errors = e.fieldErrors;
            this.shake();
          });
    }
  }
}
</script>

<style scoped lang="scss">
.wrong {
  animation: shake 0.12s linear;
  animation-iteration-count: 3;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-3%);
  }
  80% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
